import React from "react";
import { Grid, Container, Typography, Hidden } from "@mui/material";
// import ResponsiveImage from "./ResponsiveImage";
import styled from "@emotion/styled";
import IntroWave from "./svg/IntroWave.svg";
import BirdMotif from "./svg/BirdMotif.svg";
const ImageTextSection = ({ image, logo, alt, theme, isSmall }) => {
  return (
    <Container disableGutters>
      <Hidden smUp>
        <Wave src={IntroWave} alt="BuilderWave" role="presentation" />
      </Hidden>
      <Grid
        container
        direction="row"
        justifyContent={{ xs: "center", md: "space-between" }}
        alignItems={{ xs: "center" }}
        pt={{ xs: 0, md: "8rem" }}
        pb={{ xs: "3rem", md: "8rem" }}
        sx={{
          backgroundImage: `url(${BirdMotif})`,
          backgroundSize: "323px 242px",
          backgroundPosition: { xs: "right -25px bottom 40px", md: "right  bottom 40px" },
          backgroundRepeat: "no-repeat",
        }}
        // p={{ xs: "0 2rem 3rem", md: "5rem 0 0 0" }}
      >
        <Hidden mdDown>
          <ClearwaterRendering
            src={image}
            logo={logo}
            item
            alt="Clearwater Park Rendering"
            xs={12}
            md={5}
          />
        </Hidden>
        <Grid
          item
          xs={12}
          md={6}
          mr="auto"
          ml={{ sm: 0, md: "4.5rem" }}
          // pt={{ xs: "5rem" }}
          // pb={{ sm: "5rem" }}
          p={{ xs: "5rem 2rem 0", sm: "3rem 0 3rem 2rem", md: "0 0 0 5rem" }}
          component={Container}
        >
          <Typography variant="h3" align="left">
            A simple path to new home living – Water’s Edge in Clearwater Park
          </Typography>
          <Typography variant="body1" align="left">
            Water’s Edge is your destination for an innovative strategy in new home living –
            residential leasing! Water’s Edge offers a range of housing types including townhome,
            side-by-side and split-level living options. Located in Clearwater Park at Chestermere
            Lake, Water’s Edge is close to Clearwater’s retail amenities and within walking distance
            from community parks and pathways. Residents at Water’s Edge will also enjoy the
            Clearwater Park Community Clubhouse, an ideal place where individuals and families can
            enjoy the many amenities that Clearwater Park will offer.
          </Typography>
          <Typography variant="body1" align="left">
            Water’s Edge will include a total of 216 units, from 687 sq. ft. to over 2,000 sq. ft. -
            one, two and three bedroom options with attractive color schemes and home details.
            Residents who lease at Water’s Edge will enjoy contemporary finishes, quality appliances
            and professionally designed and landscaped exteriors. Residential leasing provides a
            simple path to new home living and a convenient lifestyle. Water’s Edge, your new home
            awaits!
          </Typography>
        </Grid>
        <Hidden mdUp smDown>
          <ClearwaterRendering
            src={image}
            logo={logo}
            item
            alt="Clearwater Park Rendering"
            xs={12}
            md={5}
          />
        </Hidden>
      </Grid>
    </Container>
  );
};
const Wave = styled("img")`
  position: absolute;
  left: 0;
  right: 0;
  top 0;
  bottom 0;
  width: 100%;
  height: auto;
`;

const ClearwaterRendering = styled(Grid)`
  background-image: url(${({ logo }) => logo}), url(${({ src }) => src});
  background-size: 80%, cover;
  background-repeat: no-repeat;
  background-position: top 10% center, top center;
  height: 360px;
  width: 100vw;
  ${({ theme }) => theme.breakpoints.up("sm")} {
    background-size: 80%, cover;
    background-position: top 10% center, top 20% left 36%;

    height: 540px;
    // width: 100%;
  }
  ${({ theme }) => theme.breakpoints.up("md")} {
    background-size: 80%, cover;
    background-position: top 10% center, center left 36%;

    height: 436px;
    // width: 100%;
    max-width: 458px;
  }
`;

export default ImageTextSection;
