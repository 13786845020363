import React, { useEffect } from "react";
import { Container, useMediaQuery, useTheme } from "@mui/material";

import "./App.css";
import Hero from "./Hero";

import Registration from "./Registration";
import IntroSection from "./IntroSection";
import ImageTextSection from "./ImageTextSection";
import Video from "./Video";
import AboutTheBuilder from "./AboutTheBuilder";
import Footer from "./Footer";
import BellmoreCoveRendering from "./images/BellmoreCoveRendering.jpg";
import WatersedgeRendering from "./images/WatersedgeRendering.jpg";
import ClearwaterLogoWhite from "./svg/ClearwaterLogoWhite.svg";
import { initGA } from "./Tracking/index";

function App() {
  useEffect(() => {
    initGA("G-61W2G9LF4J");
  }, []);

  const theme = useTheme();

  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Container className="App" maxWidth={"xl"} disableGutters>
      <Hero isSmall={isSmall} />
      <IntroSection
        image={WatersedgeRendering}
        alt="Watersedge rendering"
        theme={theme}
        isSmall={isSmall}
      />

      <Registration />
      <ImageTextSection
        image={BellmoreCoveRendering}
        logo={ClearwaterLogoWhite}
        alt="Bellmore Cove rendering"
        theme={theme}
      />
      <Video />
      <AboutTheBuilder theme={theme} />
      <Footer />
    </Container>
  );
}

export default App;
