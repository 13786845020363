import React from "react";
import styled from "@emotion/styled";
import { Grid, Container, Typography } from "@mui/material";

function Video(props) {
  return (
    <Wrapper id="video" maxWidth={false}>
      <Grid
        m={{ sm: "0 auto" }}
        p={{ xs: "2rem 1rem 2rem", sm: "4rem 0", md: "8rem 0 6rem" }}
        maxWidth={1200}
        container
        direction="column"
        disableGutters
      >
        <Typography
          variant="h3"
          color={"white"}
          sx={{ maxWidth: { xs: 300, sm: "initial" }, fontSize: "1rem" }}
        >
          Clearwater Park - Everyone’s Home!
        </Typography>
        <hr color="#FFFFFF" size="3px" style={{ marginBottom: "1.5rem", width: "100%" }} />
        <div
          className="wistia_responsive_padding"
          style={{ padding: "56.25% 0 0 0", position: "relative" }}
        >
          <div
            className="wistia_responsive_wrapper"
            style={{
              height: "100%",
              left: 0,
              position: "absolute",
              top: 0,
              width: "100%",
            }}
          >
            <div
              className="wistia_embed wistia_async_f6rew5okdo videoFoam=true"
              style={{ height: "100%", position: "relative", width: "100%" }}
            >
              &nbsp;
            </div>
          </div>
        </div>
      </Grid>
    </Wrapper>
  );
}

const Wrapper = styled(Container)`
  background-color: rgba(100, 135, 160, 1);
`;
export default Video;
