import React from "react";
import { Grid, Container, Typography } from "@mui/material";
// import ResponsiveImage from "./ResponsiveImage";
import styled from "@emotion/styled";

const ImageTextSection = ({ image, logo, alt, theme }) => {
  return (
    <Container disableGutters>
      <Grid
        container
        direction="row"
        justifyContent={{ xs: "center", md: "space-between" }}
        alignItems={{ xs: "center" }}
        pt={{ xs: 0, md: "8rem" }}
        pb={{ xs: "3rem", md: "8rem" }}
        // p={{ xs: "0 2rem 3rem", md: "5rem 0 0 0" }}
      >
        <Image src={image} logo={logo} item alt="Clearwater Park Rendering" xs={12} md={5} />

        <Grid
          item
          xs={12}
          md={6}
          mr="auto"
          ml={{ sm: "1rem", md: "4.5rem" }}
          // pt={{ xs: "5rem" }}
          // pb={{ sm: "5rem" }}
          p={{ xs: "5rem 2rem 0", sm: "4rem 0 0 0", md: "0 0 0 5rem" }}
          component={Container}
        >
          <Typography variant="h3" align="left">
            About Clearwater Park
          </Typography>
          <Typography variant="body1" align="left">
            Clearwater Park is inspired by nautical landscapes, prairie breezes, and people who love
            calling their place home. The community will offer the perfect blend of amenity-rich
            living and tranquil settings. Distinctive features include community retail, school
            sites, recreational areas and a senior’s residential campus. Clearwater Park will be an
            ideal home for families, retirees, singles and couples alike.
          </Typography>
          <Typography variant="body1" align="left">
            Anchored by the Clearwater Clubhouse and an iconic community lighthouse, Clearwater Park
            will offer a range of housing types and styles including home rentals. Public spaces,
            impressive landscaping and paths, and vibrant outdoor gathering spaces will connect
            neighbours for wellness, camaraderie, events and more! And with Chestermere Lake at your
            doorstep, everything and everyone, will be right at home.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

const Image = styled(Grid)`
  background-image: url(${({ logo }) => logo}), url(${({ src }) => src});
  background-size: 80%, cover;
  background-repeat: no-repeat;
  background-position: top 10% center, top center;
  height: 360px;
  width: 100vw;
  ${({ theme }) => theme.breakpoints.up("sm")} {
    background-size: 80%, cover;
    background-position: top 10% center, top 20% left 36%;

    height: 540px;
    // width: 100%;
  }
  ${({ theme }) => theme.breakpoints.up("md")} {
    background-size: 80%, cover;
    background-position: top 10% center, center left 36%;

    height: 436px;
    // width: 100%;
    max-width: 458px;
  }
`;

export default ImageTextSection;
