import React from "react";
import { Grid, Typography } from "@mui/material";

export default function Footer() {
  const today = new Date();

  return (
    <Grid container justifyContent="center" alignItems="center" bgcolor="rgb(32,37,39)" p="1rem">
      <Typography
        variant="body1"
        color="initial"
        align="center"
        color="common.white"
        fontSize=".625rem"
        maxWidth={"unset"}
        marginBottom={0}
        whiteSpace={{ xs: "preserve wrap", md: "nowrap" }}
      >
        Illustrations are artist’s concept. Due to ongoing project development features and
        specifications are subject to change without notice. Centron Group © {today.getFullYear()}{" "}
        Clearwater Park. All rights reserved.
      </Typography>
    </Grid>
  );
}
